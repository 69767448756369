.oriFunctionWrapper {
    padding: 8px 4px;
}

.ts-mention {
    background-color: #E8F4FD;
    /* Light blue background */
    border: 1px solid #0055FD;
    /* Blue border */
    border-radius: 5px;
    /* Rounded corners */
    padding: 1px 3px;
    margin-inline: 2px;
    /* Padding */
    color: #0055FD;
    /* Blue text color */
    font-weight: bold;
    /* Bold text */
}


.oriFunctionWrapper .ql-editor {
    max-height: unset !important;
}

.oriEditorWrapper .ql-container {
    font-size: 15px !important; 
    border: none !important;
}

.oriEditorWrapper .ql-container .ql-editor {
    padding: 0 !important;
}

.oriEditorWrapper .ql-container .ql-blank::before {
    left: 0 !important;
    right: 0;
    font-family: "Lato" !important;
    font-style: normal !important;
     font-size: 18px !important; 
    color: #cccccc !important;
    /* You'll need to replace this with the appropriate color value */
}

.ql-toolbar {
    border: none !important;
    padding: 0px !important;
}

.ql-toolbar button {
    width: 24px !important;
    padding: 3px !important;
}

.ql-tooltip {
    z-index: 99999;
}

.ql-snow a {
    color: white !important;
    /* You'll need to replace this with the appropriate color value */
}

.ql-snow .ql-stroke {
    stroke: white !important;
    /* You'll need to replace this with the appropriate color value */
}

.ql-snow .ql-fill {
    fill: white !important;
    /* You'll need to replace this with the appropriate color value */
}

.ql-snow .ql-picker-options {
    margin-bottom: 5px;
    bottom: 100%;
    top: unset !important;
}

.ql-active {
    background-color: white !important;
    /* You'll need to replace this with the appropriate color value */
    font-weight: bold !important;
}