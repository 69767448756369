//================================== GLOBAL ========================================
p {
  margin: 0;
}

#saas-dashboard-voice-genie {
  background : url('../src/data/assets/img/bgimage.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#rc-anchor-container {
  display: none;
}

.grecaptcha-badge{
  visibility: hidden;
}


//================================== MIXINS ========================================

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-ms-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

//================================== KEYFRAMES ========================================

@include keyframes(vg-rotating) {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@include keyframes(vg-pulse) {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@include keyframes(vg-fadeIn) {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@include keyframes(vg-fadeOut) {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@include keyframes(vg-fadeInRight) {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@include keyframes(vg-zoomIn) {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@include keyframes(vg-zoomOut) {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

//================================== CLASS NAMES ========================================

.vg-hoverableWrapper {
  .vg-wrapperHoverHidden {
    display: none;
  }

  &:hover {
    .vg-wrapperHoverHidden {
      display: block;
    }
  }

  .vg-wrapperHoverVisible {
    display: inherit;
  }

  &:hover {
    .vg-wrapperHoverVisible {
      display: none;
    }
  }
}

.vg-animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.vg-rotating {
  @include animation(vg-rotating 1s linear infinite);
}

.vg-pulse {
  @include animation((vg-pulse 1.5s infinite));
}

.vg-fadeIn {
  @include animation(vg-fadeIn 500ms);
}

.vg-fadeOut {
  @include animation(vg-fadeOut 500ms);
}

.vg-fadeInRight {
  @include animation(vg-fadeInRight 500ms);
}

.vg-zoomIn {
  @include animation(vg-zoomIn 500ms);
}

.vg-zoomOut {
  @include animation(vg-zoomOut 500ms);
}

#confetti canvas {
  z-index: 999999 !important
}